<form [formGroup]="editForm">
    <mat-form-field appearance="outline" class="w-100">
        <mat-label *ngIf="input.title">{{input.title}}
          <span *ngIf="input.required">*</span> 
        </mat-label>
        <mat-icon *ngIf="input.icon" matPrefix>{{input.icon}}</mat-icon>
        <mat-select [compareWith]="compareObjects" disableOptionCentering [panelClass]="customPanelClass || 'myPanelClass'" [formControl]="tags" multiple (selectionChange)="onSelect($event)" (openedChange)="focusOnSearchBar()">
          <mat-select-trigger>
            {{tags.value.length ? (tags.value[0][input.nameField] || tags.value[0].name || tags.value[0]): input.placeholder}}
            <span *ngIf="tags.value.length > 1" class="additional-selection">
              (+{{tags.value.length - 1}} {{tags.value.length === 2 ? 'other' : 'others'}})
            </span>
          </mat-select-trigger>
          <div class="tags ml-3" [ngClass]="{'hide-tags-display':!allComplete}">
          </div>
          <div *ngIf="!disabled" class="tags ml-3" [ngClass]="{'hide-tags-display':allComplete}">
            <div *ngFor="let item of tags.value" class="tag d-flex h-100 align-items-baseline mt-2 mb-1 ml-1">
                <span class="body black">{{item.name || item[input.nameField] || item}}</span>
                <mat-icon (click)="removeRole(item)">cancel</mat-icon>
            </div>
          </div>
          <input *ngIf="!disabled" #searchBar matInput class="input-style mt-0 mr-0 mb-0 ml-2 pl-2" placeholder="{{input.placeholder}}" formControlName="input" [required]="input.required" (keydown.space)="$event.stopPropagation()"/>
          <ng-container *ngIf="options.size > 0; else noOptions">
            <mat-checkbox *ngIf="!disabled" class="ml-3 select-all-box"  [color]="'primary'" [checked]="allComplete" [indeterminate]="someComplete" #allSelected (change)="toggleSelection($event)">Select All</mat-checkbox>
            <mat-option [disabled]="disabled" *ngFor="let option of options" class="option-checkbox" [value]="option">
              {{option[input.nameField] || option.name || option}}
            </mat-option>
          </ng-container>
          <ng-template #noOptions>
            <mat-option class="hide-check-box" disabled>No options available</mat-option>
          </ng-template>
        </mat-select>
        <mat-icon *ngIf="input.suffixIcon" matSuffix class="mat-custom-arrow">{{input.suffixIcon}}</mat-icon>
    </mat-form-field>
</form>
