import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { debounceTime, startWith, map } from 'rxjs/operators';
import { SearchableMultiselect } from './searchable-multiselect.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DataService } from 'src/app/services/data.service';
import { MatOption } from '@angular/material/core';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-searchable-multiselect',
  templateUrl: './searchable-multiselect.component.html',
  styleUrls: ['./searchable-multiselect.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SearchableMultiselectComponent,
    multi: true
  }]
})
export class SearchableMultiselectComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() input: SearchableMultiselect;
  @Input() customPanelClass: string;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  editForm: FormGroup;
  search: FormControl = new FormControl('');
  tags = new FormControl([]);
  selectedItems = [];
  searchVal: any;
  options = new Set();
  allComplete: boolean = false;
  someComplete: boolean = false;
  disabled: boolean = false;
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('searchBar') searchBar: ElementRef;
  constructor(private ds: DataService, private fb: FormBuilder, private util: UtilitiesService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.input.currentValue.selectedItems) {
      this.selectedItems = changes.input.currentValue.selectedItems;
      this.input.selectedItems = cloneDeep(this.selectedItems);
      this.tags.setValue(this.selectedItems);
      this.setDisabledValues(this.input.disabled);
    }
    if(changes.input.currentValue.options) {
      this.preProcessData();
    }
  }

  ngOnInit(): void {
    if (this.input?.cascading) {
      this.selectedItems = [...this.options];
      this.input.selectedItems = cloneDeep(this.selectedItems);
      this.tags.setValue(this.selectedItems);
      this.allComplete = true;
    }
    this.preProcessData();
  }

  compareObjects(o1: any, o2: any): boolean {
    const idField = o1['_id'] ? '_id' : 'id';
    return o1[idField] === o2[idField];
  }

  preProcessData() {
    const validators = Array.isArray(this.input.validators) ? this.input.validators : [];
    this.setDisabledValues(this.input.disabled);
    this.editForm = this.fb.group({
      input: new FormControl({ value: '', disabled: this.input.disabled }, this.input.required ? [Validators.required, ...validators] : validators)
    });
    if (this.input?.options?.length) {
      this.options = new Set(this.input.options)
      this.tags.setValue(this.selectedItems);
      this.editForm.controls['input'].valueChanges.subscribe(val => {
        const filteredArray = this.input.options?.filter(option => (option[this.input.nameField] || option.name || option).toLowerCase().includes((val).toLowerCase()));
        this.options = new Set(filteredArray);
      })
    } else {
      this.editForm.controls['input'].valueChanges.pipe(startWith(''), debounceTime(700)).subscribe(val => {
        this.searchVal = val;
        this.getData();
      });
    }
  }

  getData() {
    if(!this.disabled) {
      this.ds.searchData(this.input.endpoint, {...this.input.apiParams, search: this.searchVal, clientId: this.ds.currentAdminClientId}).subscribe(response => {
        let newData = [];

        if(response['searchData']) {
          newData = response['searchData'];
        } else if(this.input.responseKey && Array.isArray(response[this.input.responseKey])) {
          newData = response[this.input.responseKey];
        } else if (this.input.responseKey && response['data'] && response['data']?.[this.input.responseKey]) {
          newData = response['data'][this.input.responseKey];
        }
        const newDataSet = new Set(newData);
        const aNotB = this.util.getANotB(new Set(this.tags.value), newDataSet);
        const finalOptions = newData.concat(aNotB);
        const finalOptionsSet = new Set(finalOptions);
        let aAndB = this.util.getAAndB(finalOptionsSet, new Set(this.tags.value));
        if (!aAndB?.length) {
          aAndB = this.util.getAAndB(finalOptionsSet, new Set(this.selectedItems));
        }
        this.options = finalOptionsSet;
        this.tags.setValue(aAndB);
        this.updateAllComplete();
        this.updateSomeComplete();
      });
    }
  }

  setDisabledValues(disabled: boolean) {
    this.disabled = disabled;
    if(disabled) {
      this.options = new Set(this.selectedItems);
      this.tags.setValue(this.selectedItems);
    }
  }

  removeRole(item): void{
    this.tags.setValue(this.tags.value.filter(tag=>!this.util.deepEqual(tag, item)));
    this.selectionChange.emit({inputObj: this.input, selectedItems: [...this.tags.value]});
    this.updateAllComplete();
    this.updateSomeComplete();
  }
  onSelect(event: any) {
    this.selectionChange.emit({inputObj: this.input, selectedItems: [...this.tags.value]});
    this.updateAllComplete();
    this.updateSomeComplete();
  }
  clearDropdownOptions() {
    this.options.clear();
  }
  updateAllComplete() {
    this.allComplete = this.tags.value != null && this.tags.value.length == this.options.size;
  }
  updateSomeComplete() {
    if (this.tags.value == null || this.tags.value.length == 0) {
      this.someComplete = false;
    }
    this.someComplete = this.tags.value.length > 0 && !this.allComplete;
  }
  toggleSelection(event) {
    this.someComplete = false;
    if (event.checked) {
      const allItems = [...this.options];
      this.selectedItems = allItems;
      this.input.selectedItems = cloneDeep(this.selectedItems);
      this.allComplete = true;
      this.tags.setValue(allItems);
    } else {
      this.allComplete = false;
      this.selectedItems = [];
      this.input.selectedItems = cloneDeep(this.selectedItems);
      this.tags.setValue([]);
    }
    this.selectionChange.emit({inputObj: this.input, selectedItems: [...this.tags.value]});
  }
  focusOnSearchBar() {
    this.searchBar?.nativeElement.focus();
  }
  set value(val: string){
    this.onChange(val)
    this.onTouch(val)
  }
  writeValue(value: any[]): void {
    this.tags.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
  onChange: any = () => {}
  onTouch: any = () => {}
}
