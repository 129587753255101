import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedDialogComponent } from '../modules/common-components/shared-dialog/shared-dialog.component';
import { DIALOG_TYPES } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class WaitErrorDialogsService {
  data: any;
  dialogRef: MatDialogRef<SharedDialogComponent, any>;
  constructor(private dialog: MatDialog) { }

  showDialog(data): MatDialogRef<SharedDialogComponent, any> {
    this.data = data;

    if (!data.dontCloseAllDialogs) {
        this.dialog.closeAll();
    }

    if(data.isApiFailureDialog && this.dialogRef?.componentInstance?.type === DIALOG_TYPES.WAIT){
     this.dialogRef.close();
    }

    if(!!this.dialogRef && !this.dialogRef.componentInstance) this.dialogRef.close();
    this.dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: data.type == 'wait' });
    this.dialogRef.componentInstance.data = data;
    const onEvent = this.dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      this.dialogRef.close();
    });
    this.dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
    return this.dialogRef;
  }
  closeDialog(dialogRef?: MatDialogRef<SharedDialogComponent, any>) {
    dialogRef? dialogRef.close(): this.dialog.closeAll();
  }
}
