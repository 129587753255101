import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationTriggerDdComponent } from '../../../notification-triggers/notification-trigger-dd/notification-trigger-dd.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IUserGroup, IUserGroupApiResponse } from '../user-groups';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IGrowthPlan, IGrowthPlanResponse } from '../../layouts/admin-goal-management-layout';
import { IAccomplishment, ICourse, IDevelopmentPlanRequest, IFormData, IMasterCourseDataResponse, IMasterCourseData, ISelectedAccomplishmentResponse, ISkillDetailsResponse, ISkillResponseData, ICertificationMasterData, ICertification, IUserResponse, IUserReportees } from '.';
import { DataService } from 'src/app/services/data.service';
import { take } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { DIALOG_TYPES } from 'src/app/constants';
import { MatTableDataSource } from '@angular/material/table';
import { IGoal } from '../goals';
import { Observable, Subject } from 'rxjs';
import { GoalService } from 'src/app/services/goal.service';

export interface IGoalEntityTypeRole {
  title: string;
  mandatorySkills: ISkill[];
  optionalSkills: ISkill[];
  totalCurrentRating: number;
  totalExpectedRating: number;
}

export interface ISkill {
  skillName: string;
  currentRating: number;
  expectedRating: number;
}

export enum AccomplishmentType {
  Skill = 'skill',
  Custom = 'custom',
  Course = 'course',
  Certification = 'certification',
  Specialization = 'specialization',
  Role = 'role',
  Designation = 'designation',
}

export enum DevelopmentPlanStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  NoPlans = 'No development plans',
}

export enum AccomplishmentId {
  Skill = '1',
  Custom = '2',
  Course = '3',
  Certification = '4',
  Specialization = '5',
  Role = '6',
}

@Component({
  selector: 'app-add-goal',
  templateUrl: './add-goal.component.html',
  styleUrls: ['./add-goal.component.scss']
})
export class AddGoalComponent implements OnInit {

  @ViewChild('skillSelect') skillSelect!: NgSelectComponent;
  @ViewChild('courseSelect') courseSelect!: NgSelectComponent;

  @Output() onEvent = new EventEmitter();
  @Output() refresh = new EventEmitter();
  @Output() loadMore = new EventEmitter<void>();

  public title: string = 'Add New Goal';

  public editGoalObj: IGoal;
  public isMyGoals: boolean = false;
  public isTeamGoals: boolean = false;
  public userIds: string[];

  public userGroupData: IUserGroupApiResponse;
  public growthPlanData: IGrowthPlanResponse;

  public accomplishments: IAccomplishment[] = [
    { id: '1', type: AccomplishmentType.Skill,          label: 'Develop a skill',           icon: '', selected: false },
    { id: '2', type: AccomplishmentType.Custom,         label: 'Custom Goal',               icon: '', selected: false },
    { id: '3', type: AccomplishmentType.Course,         label: 'Complete a course',         icon: '', selected: false },
    { id: '4', type: AccomplishmentType.Certification,  label: 'Complete a certification',  icon: '', selected: false },
    { id: '5', type: AccomplishmentType.Specialization, label: 'Complete a specialization', icon: '', selected: false },
    { id: '6', type: AccomplishmentType.Role,           label: 'Develop for a role',        icon: '', selected: false },
  ];

  public goalTypeIconsMap: { [key: string]: string } = this.goalService.goalTypeIconsMap;

  public selectedAccomplishment: any = null;
  public dataForSelectedAccomplisment: any[] = [];
  public selectedAccomplismentApiResponse: ISkillResponseData | ISelectedAccomplishmentResponse;
  public subCategoryAccomplishment = this.subCategoryAccomplishmentInit();
  public dynamicFormControls: { [key: string]: { showSelect: boolean, selectedItem: any } } = {};

  public planForm: FormGroup;
  public developmentPlans: IGrowthPlan[] = [];
  public ratings = [
    { id: 0, value: 1 },
    { id: 1, value: 2 },
    { id: 2, value: 3 },
    { id: 3, value: 4 }
  ];
  public improvementMethods = ['Workshop', 'Training', 'Certification', 'Project Experience'];
  public measurementOptions = [
    { label: 'Complete/Incomplete', value: 'complete_incomplete' },
    { label: 'Numeric', value: 'numeric' },
    { label: 'Currency ($, ₹, €)', value: 'currency' },
    { label: '% Percentage', value: 'percentage' }
  ];
  public lockGoalsSetting = false;
  public activePlanEndDate = new Date(2024, 11, 31);
  public allSelected: boolean = false;
  public isLoading = false; // To prevent multiple requests

  public displayedColumns: string[] = ['skill', 'current', 'expected'];

  public dataSourceMandatory: MatTableDataSource<any>;
  public dataSourceOptional: MatTableDataSource<any>;
  public dataSourceCondtionalMandatory: MatTableDataSource<any>;
  public showGoalOwnerDropdown: boolean = false
  public usersList: IUserReportees[] = [];

  // TODO: remove once role, specialization data is coming correctly [what should be the logic to calculate?]
  public dynamicBadgeText: string = 'Any 1 skill';

  private dataForSelectedAccomplisment$ = new Subject();

  private readonly CATEGORY_ASSIGNED = 'ASSIGNED';
  private readonly CATEGORY_RECOMMEND = 'RECOMMENDED';
  private readonly MANDATORY = 'mandatory';
  private readonly OPTIONAL = 'optional';

  private ENDPOINT_GOAL_DATA = API_ENDPOINT.GET_GOAL_DATA_LIST_IDP;
  private apiMethod: (endpoint: string, payload: any) => Observable<any>;


  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public util: UtilitiesService,
    private ds: DataService,
    public weds: WaitErrorDialogsService,
    private goalService: GoalService
  ) {}

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {

    this.ENDPOINT_GOAL_DATA = (this.isMyGoals || this.isTeamGoals) ? API_ENDPOINT.GET_GOAL_DATA_LIST_MY_GOALS : this.ENDPOINT_GOAL_DATA;
    this.apiMethod =  (this.isMyGoals || this.isTeamGoals) ? this.ds.careerPrismDataPostApi.bind(this.ds) : this.ds.postApi.bind(this.ds);

    this.initialConfigForAccomplishment();
    this.HandleEditGoalScenario();
    this.createForm();
    this.watchSelectedAccomplishmentValueChanges();

    if(this.growthPlanData?.data?.length) {
      this.developmentPlans = [...this.growthPlanData.data];
      this.checkDevelopmentPlans();
    }

    if(this.isTeamGoals) {
      this.getGoalOwners();
    }
  }

  //------------------------------------------------------------------
  // Public Methods
  //------------------------------------------------------------------

  public checkDevelopmentPlans(): void {
    const activePlans = this.developmentPlans.filter(plan => plan.status !== DevelopmentPlanStatus.Active);
    if (activePlans.length === 0) {
      this.planForm.get('developmentPlan')?.disable();
      this.planForm.get('developmentPlan')?.setValue('No development plans');
    } else if (this.lockGoalsSetting) {
      this.developmentPlans = this.developmentPlans.filter(plan => plan.status === DevelopmentPlanStatus.Active);
    }
  }

  public toggleSelect(accomplishmentId: string): void {
    this.dynamicFormControls[accomplishmentId].showSelect = true;
    setTimeout(() => {
      if (accomplishmentId === AccomplishmentId.Skill && this.skillSelect) {
        this.skillSelect.open();
      } else if (accomplishmentId === AccomplishmentId.Course && this.courseSelect) {
        this.courseSelect.open();
      }
    });
  }

  public toggleAllUsers(event: Event): void {
    this.allSelected = !this.allSelected;
    this.userGroupData.data.userGroupDetails.forEach(group => group.selected = this.allSelected);
    this.updateFormValue();
  }

  public syncCheckboxes(event: Event): void {
    const selectedUserGroups: any[] = this.planForm.get('userGroups').value;
    this.allSelected = false;

    this.userGroupData.data.userGroupDetails.forEach(group => {
      group.selected = selectedUserGroups.includes(group);
    });
  }

  public onRemoveClick(item: IUserGroup) {
    this.removeItemFromControl('userGroups', item, 'userGroupId');
  }

  public onItemSelect(accomplishmentId: string, item: { id: string, title: string }): void {
    // reset
    this.initialConfigForAccomplishment();
    this.subCategoryAccomplishment = this.subCategoryAccomplishmentInit();
    // TODO: fix this reset once all done
    this.dataSourceMandatory = null;
    this.dataSourceOptional = null;
    this.dataSourceCondtionalMandatory = null;
    this.dynamicFormControls[accomplishmentId].selectedItem = item;
    this.dynamicFormControls[accomplishmentId].showSelect = false;

    switch(accomplishmentId) {
      case AccomplishmentId.Skill:
        this.getEnrichmentData(item.id, AccomplishmentType.Skill);
      break;
      case AccomplishmentId.Course:
        this.getEnrichmentData(item.id, AccomplishmentType.Course);
      break;
      case AccomplishmentId.Certification:
        this.getEnrichmentData(item.id, AccomplishmentType.Certification);
      break;
      case AccomplishmentId.Specialization:
        this.getEnrichmentData(item.id, AccomplishmentType.Specialization);
      break;
      case AccomplishmentId.Role:
        this.getEnrichmentData(item.id, AccomplishmentType.Role);
      break;
    }
  }

  public deselect(accomplishmentId: string): void {
    this.dynamicFormControls[accomplishmentId].selectedItem = null;
  }

  public onPlanSelect(event: any): void {
    const selectedPlan = this.developmentPlans.find(plan => plan.developmentPlanId === event.value);
    if (selectedPlan?.status === 'Active' && this.planForm.get('dueDate')?.value > this.activePlanEndDate) {
      this.planForm.get('dueDate')?.setValue(this.activePlanEndDate);
    }
  }

  public onDueDateChange(event: MatDatepickerInputEvent<Date>): void {
    const dueDate = event.value;
    const selectedPlan = this.developmentPlans.find(plan => plan.developmentPlanId === this.planForm.get('developmentPlan')?.value);
    if (selectedPlan?.status === 'Active' && dueDate && dueDate > this.activePlanEndDate) {
      this.planForm.get('dueDate')?.setValue(this.activePlanEndDate);
    }
  }

  public newUserGroup(event?: any, option?: any): any {
    let dialogRef = this.dialog.open(NotificationTriggerDdComponent, {
      width: window.innerWidth < 900 ? 0.9 * window.innerHeight + 'px' : '600px', height: window.innerWidth < 900 ? 0.9 * window.innerHeight + 'px' : '600px',
      panelClass: 'notification-dialog',
      disableClose: true
    });
    dialogRef.componentInstance.notificationData = {};
    dialogRef.componentInstance.config = { 'enableDelete': true };
    dialogRef.componentInstance.onEvent.subscribe((res) => {
      if (res.type == 'REFRESH') {
        dialogRef.close()
        // this.getData(false);
      }
    });
  }

  public onScrollToEnd() {
    this.loadUserGroups();
  }

  public onClose() {
    this.onEvent.emit();
  }

  public addNewGoal() {
    console.log('addNewGoal', this.planForm.value);
    const selectedAccomplishment: any = this.dynamicFormControls[this.planForm.value?.selectedAccomplishment]?.selectedItem ?? '';
    const requestObj = this.mapToUpdateGoalRequestFormat(this.planForm.value, selectedAccomplishment);
    console.log('requestObj', requestObj);

    if(this.editGoalObj) {
      this.updateGoal(requestObj);
    } else {
      this.createNewGoal(requestObj);
    }
  }

  public dismiss() {
    this.onEvent.emit();
  }

  public onDataLoaded() {
    this.isLoading = false;
  }

 public onAccomplishmentChange(selectedItem: any): void {
    console.log('Selected Accomplishment:', selectedItem);
    this.dataForSelectedAccomplisment = [];
    this.selectedAccomplismentApiResponse = null;

    switch (selectedItem.type) {
        case AccomplishmentType.Course:
            this.getCourses();
            break;
        case AccomplishmentType.Skill:
            this.getSkills();
            break;
        case AccomplishmentType.Specialization:
            this.getSpecializations();
            break;
        case AccomplishmentType.Certification:
            this.getCertifications();
            break;
        case AccomplishmentType.Role:
            this.getDesignation();
            break;
    }
  }

  public showUserSelect() {
    this.showGoalOwnerDropdown = true;
  }

  public onRemoveUserClick(item: IUserReportees) {
    this.removeItemFromControl('goalOwner', item, 'userId');
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private mapDevelopmentPlan(developmentPlanId: string) {
    const selectedPlan = this.developmentPlans.find(plan => plan.developmentId === developmentPlanId);
    if (selectedPlan) {
      return {
        $ngOptionLabel: selectedPlan.name,
        $ngOptionValue: selectedPlan.developmentId,
      };
    }
    return null;
  }

  private getGoalOwners() {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const requestPayload = this.buildRequestPayloadAdmin('DIRECT_REPORTING_EMPLOYEES' as any);
    requestPayload.forAutoComplete = null;
    requestPayload.filters = [];
    requestPayload.sort = {
            field: 'name',
            order: 'ASC'
    };
    requestPayload.offset = 0;
    this.apiMethod(API_ENDPOINT.GET_GOAL_OWNERS, requestPayload).pipe(take(1)).subscribe((res: IUserResponse) => {
        this.weds.closeDialog(dialogRef);
        this.usersList = res.data;

        if(this.editGoalObj) {
          const usersList = [];
          this.editGoalObj.goalOwner.forEach(id => {
            const found = this.usersList.find(user => user.userId === id);
            usersList.push(found);
          });
          this.planForm.patchValue({goalOwner: usersList});
        }
    });
  }

  private getEnrichmentData(id: string, type: AccomplishmentType) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const client = this.ds.currentAdminClientId;
    const requestPayload = {
      clientId: client,
      type: type,
      id: id,
    };

    const endpoint = (this.isMyGoals || this.isTeamGoals) ? API_ENDPOINT.GET_EXTRA_DETAILS_MYGOAL : API_ENDPOINT.GET_EXTRA_DETAILS_IDP;

    this.ds.postApi(endpoint, requestPayload).pipe(take(1)).subscribe((res: ISkillDetailsResponse | IMasterCourseDataResponse | ICertificationMasterData | any) => {
      console.log('ISkillDetailsResponse', res);
        this.weds.closeDialog(dialogRef);
        this.subCategoryAccomplishment[type] = { ...res?.data?.masterGoalData ?? {} };

      switch (type) {
        case AccomplishmentType.Course:
          this.subCategoryAccomplishment.course = this.mapMasterGoalDataToCourse(this.subCategoryAccomplishment[type]);
          break;
        case AccomplishmentType.Skill:
          break;
        case AccomplishmentType.Certification:
          this.subCategoryAccomplishment.certification = this.mapMasterGoalDataToCertification(this.subCategoryAccomplishment[type]);
          break;
        case AccomplishmentType.Specialization:
        case AccomplishmentType.Role:
          this.handleDetailResponseForRoleAndSpecialization(type);
          break;
      }
    });
  }

  private handleDetailResponseForRoleAndSpecialization(type: AccomplishmentType) {
    const mappedData = this.tranformDataForTable(type);
    this.dataSourceMandatory = new MatTableDataSource(mappedData.mandatory);
    this.dataSourceOptional = new MatTableDataSource(mappedData.optional);
    this.dataSourceCondtionalMandatory = new MatTableDataSource(mappedData.mandatoryoptional);
  }

  private tranformDataForTable(type: AccomplishmentType) {
    const mappedData = {
      title: this.subCategoryAccomplishment[type]?.name,
      specializationId: this.subCategoryAccomplishment[type]?.specializationId,
      mandatory: [],
      optional: [],
      mandatoryoptional: [],
    };

    this.subCategoryAccomplishment[type]?.skillItems.forEach(item => {
      const mappedItem = {
        skillName: item.skillName,
        expectedRating: item.exitRating,
        currentRating: 'NA'
      };

      if (item.category.toLowerCase() === this.MANDATORY) {
        mappedData.mandatory.push(mappedItem);
      } else if (item.category.toLowerCase() === this.OPTIONAL) {
        mappedData.optional.push(mappedItem);
      } else {
        mappedData.mandatoryoptional.push(mappedItem);
      }
    });
    return mappedData;
  }

  private getSkills() {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const requestPayload = (this.isMyGoals || this.isTeamGoals) ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Skill) : this.buildRequestPayloadAdmin(AccomplishmentType.Skill);
    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISkillResponseData) => {
        this.weds.closeDialog(dialogRef);
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = res.data?.map(item => ({ title: item.title, id: item.pk })) ?? [];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private getCourses() {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const requestPayload = (this.isMyGoals || this.isTeamGoals) ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Course) : this.buildRequestPayloadAdmin(AccomplishmentType.Course);

    if(!this.isMyGoals && !this.isTeamGoals) {
      requestPayload.filterOnRole = 1;
    }
    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISelectedAccomplishmentResponse) => {
        this.weds.closeDialog(dialogRef);
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = res.data.searchData.map(course => ({ title: course.coursename, id: course.id })) ?? [];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private getSpecializations() {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const requestPayload = (this.isMyGoals || this.isTeamGoals) ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Specialization) : this.buildRequestPayloadAdmin(AccomplishmentType.Specialization);

    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISelectedAccomplishmentResponse) => {
        this.weds.closeDialog(dialogRef);
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = res.data.searchData?.map(item => ({ title: item.specializationname, id: item.id })) ?? [];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private getCertifications() {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const requestPayload = (this.isMyGoals || this.isTeamGoals) ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Certification) : this.buildRequestPayloadAdmin(AccomplishmentType.Certification);
    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISelectedAccomplishmentResponse) => {
        this.weds.closeDialog(dialogRef);
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = res.data.searchData?.map(item => ({ title: item.certificationname, id: item.id })) ?? [];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private getDesignation() {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const requestPayload = (this.isMyGoals || this.isTeamGoals) ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Designation) : this.buildRequestPayloadAdmin(AccomplishmentType.Designation);

    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISelectedAccomplishmentResponse) => {
        this.weds.closeDialog(dialogRef);
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = res.data.searchData?.map(item => ({ title: item.designationname, id: item.designationId })) ?? [];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private watchSelectedAccomplishmentValueChanges() {
    this.planForm.get('selectedAccomplishment')?.valueChanges.subscribe(selectedId => {
      const selectedAccomplishment = this.accomplishments.find(a => a.id === selectedId);
      if (selectedAccomplishment && selectedAccomplishment.id === AccomplishmentId.Certification && selectedAccomplishment.type === AccomplishmentType.Certification) {
        this.planForm.get('managerApproval')?.setValue(true);
      } else {
        this.planForm.get('managerApproval')?.setValue(false);
      }
    });

  }

  private initialConfigForAccomplishment() {
    this.accomplishments.forEach(accomplishment => {
      this.dynamicFormControls[accomplishment.id] = { showSelect: false, selectedItem: null };
    });
  }

  private createForm() {
    this.planForm = this.fb.group({
      selectedAccomplishment: [null, Validators.required],
      enrichmentList: [null],
      developmentPlan: [{ value: '', disabled: false }, Validators.required],
      dueDate: [''],
      visibility: ['', Validators.required],
      goalName: ['', [Validators.maxLength(200)]],
      goalDescription: ['', [Validators.maxLength(500)]],
      currentRating: [{ value: '(Differs across users)', disabled: true }],
      targetRating: [''],
      measurement: [''],
      improvementMethod: [''],
      managerApproval: [false],
    });

    if (!this.isMyGoals && !this.isTeamGoals) {
      this.planForm.addControl('category', this.fb.control(null, Validators.required));
      this.planForm.addControl('userGroups', this.fb.control(null, Validators.required));
    }
    if (this.isTeamGoals) {
      this.planForm.addControl('goalOwner', this.fb.control(''));
    }
  }

  private patchEditValue(editGoalObj: IGoal) {
    if (editGoalObj) {
      const selectedAccomplishmentId = this.accomplishments.filter(x => x.type === editGoalObj.type.toLowerCase())[0].id;
      const userGroups = [];
      if(this.userGroupData?.data) {
        this.userGroupData.data.userGroupDetails.forEach(group => {
          if (editGoalObj.userGroups.includes(group.id)) {
            userGroups.push(group);
          }
        });
      }

      const enrichmentList = this.dataForSelectedAccomplisment.filter(x => x.id === editGoalObj.typeIdentifier)[0];
      this.dynamicFormControls[selectedAccomplishmentId].selectedItem = enrichmentList;
      const developmentPlan = this.mapDevelopmentPlan(editGoalObj?.developmentPlanId);
      this.planForm.patchValue({
        selectedAccomplishment: selectedAccomplishmentId,
        goalName: editGoalObj.title,
        developmentPlan: developmentPlan,
        goalDescription: editGoalObj?.description,
        measurement: editGoalObj?.measurement,
        improvementMethod: editGoalObj?.improvementMethod,
        targetRating: editGoalObj?.requiredValue,
        userGroups: userGroups ?? null,
        category: editGoalObj.category === this.CATEGORY_ASSIGNED ? 2 : 1,
        dueDate: new Date(editGoalObj.goalDeadline),
        visibility: null,
        managerApproval: this.editGoalObj.approvalStatus === 'PENDING',
        goalOwner: editGoalObj?.goalOwner,
    });

    if(!this.isMyGoals && !this.isTeamGoals) {
      this.planForm.controls.userGroups.disable();
      setTimeout(() => {
        this.planForm.patchValue({enrichmentList: enrichmentList});
      }, 0);
    }
    }
  }

  private loadUserGroups() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loadMore.emit();
    }
  }

  private updateFormValue(): void {
    const selectedGroups = this.userGroupData.data.userGroupDetails.filter(group => group.selected);
    this.planForm.get('userGroups').setValue(selectedGroups);
  }

  private removeItemFromControl(controlName: string, item: any, identifier: string): void {
    const control = this.planForm.get(controlName);
    if (control) {
      const items = control.value || [];
      const updatedItems = items.filter((i: any) => i[identifier] !== item[identifier]);
      control.setValue(updatedItems);
      if (controlName === 'userGroups') {
        this.syncCheckboxes(null);
      }
    }
  }

  private mapToUpdateGoalRequestFormat(formValue: IFormData, { title, id }: { title: string; id: string }): IDevelopmentPlanRequest {
    const type: any = this.getAccomplishmentType(formValue);
    const res: IDevelopmentPlanRequest = {
      data: {
        goalId: this.editGoalObj?.goalId,
        title: this.getTitle(type, title, formValue.goalName),
        description: formValue.goalDescription,
        developmentPlanId: formValue.developmentPlan,
        category: formValue.category === 1 ? this.CATEGORY_ASSIGNED : this.CATEGORY_RECOMMEND,
        type: type,
        typeIdentifier: id,
        currentValue: null,
        requiredValue: formValue.targetRating.toString(),
        sendForApproval: formValue.managerApproval,
        userGroupIds: this.generateUserGroupId(formValue),
        goalDeadline: formValue.dueDate ? new Date(formValue.dueDate).toISOString() : null
      }
    };

    if(this.isMyGoals) {
      res.data.userId = [this.ds.user.userId]
    }

    if(this.isTeamGoals) {
      res.data.userId = this.userIds;
    }

    if(AccomplishmentType.Skill.toUpperCase() === type) {
      res.data.improvementMethod = formValue.improvementMethod.toUpperCase();
    }

    return res;
  }

  private generateUserGroupId(formValue: IFormData): string[] {
    return this.editGoalObj ? this.editGoalObj.userGroups : formValue.userGroups?.map(group => group.id);
  }

  private getAccomplishmentType(formValue: IFormData): string {
    return this.accomplishments.find(x => x.id === formValue.selectedAccomplishment)?.type?.toUpperCase();
  }

  private createNewGoal(requestObj: IDevelopmentPlanRequest) {
    const clientId = this.ds.currentAdminClientId;
    this.ds.careerPrismDataPostApi(API_ENDPOINT.ADD_NEW_GOAL_ADMIN(clientId), requestObj).pipe(take(1)).subscribe((res: any) => {
      console.log(res);
      if(res.ok) {
        this.createForm();
        this.resetUserGroupSelection();
        this.refresh.emit();
        this.dismiss();
      }
    });
  }

  private updateGoal(requestObj: IDevelopmentPlanRequest) {
    const clientId = this.ds.currentAdminClientId;
    const userId = this.ds.user.userId;
    this.ds.careerPrismDataPostApi(API_ENDPOINT.UPDATE_GOAL_ADMIN(clientId), requestObj).pipe(take(1)).subscribe((res: any) => {
      console.log(res);
      if(res.ok) {
        this.createForm();
        this.resetUserGroupSelection();
        this.refresh.emit();
        this.dismiss();
      }
    });
  }

  private resetUserGroupSelection() {
    this.userGroupData.data.userGroupDetails.forEach(group => group.selected = false);
  }

  private getTitle(type: AccomplishmentType, name: string, title: string): string {
    switch (type.toLowerCase()) {
      case AccomplishmentType.Specialization:
        return `Complete ${name} specialization`;
      case AccomplishmentType.Skill:
        return `Develop ${name} skill`;
      case AccomplishmentType.Certification:
        return `Complete ${name} certification`;
      case AccomplishmentType.Role:
        return `Develop into ${name} role`;
      case AccomplishmentType.Course:
        return `Complete ${name} course`;
        default:
          return title;
    }
  }

  // TODO: Move to service after all merged
  private mapMasterGoalDataToCourse(masterGoalData: IMasterCourseData): Partial<ICourse> {
    return {
      _id: masterGoalData.courseId,
      clientId: this.ds.currentAdminClientId,
      description: masterGoalData.courseDescription,
      pk: masterGoalData.courseId,
      skills: masterGoalData.skillItemIds,
      status: masterGoalData.status.toUpperCase(),
      title: masterGoalData.courseName,
      type: AccomplishmentType.Course.toUpperCase(),
      enabled: masterGoalData.enabled,
      learningElementPk: masterGoalData.courseId,
    };
  }

  // TODO: Move to service after all merged
  private mapMasterGoalDataToCertification(masterGoalData: ICertificationMasterData): Partial<ICertification> {
    return {
      _id: masterGoalData.certificationId,
      clientId: this.ds.currentAdminClientId,
      createdAt: masterGoalData.createddt,
      description: masterGoalData.data.certificationDescription,
      originCreator: masterGoalData.data.certificationIssuedBy,
      pk: masterGoalData.certificationId,
      status: masterGoalData.data.status.toUpperCase(),
      title: masterGoalData.data.certificationName,
      type: AccomplishmentType.Certification.toUpperCase(),
      updatedAt: masterGoalData.modifieddt,
      enabled: masterGoalData.enabled,
      learningElementPk: masterGoalData.certificationId,
    };
  }

  private subCategoryAccomplishmentInit() {
    return  {
      skill: null,
      course: null,
      certification: null,
      specialization: null,
      role: null
    }
  }

  private HandleEditGoalScenario() {
    if (this.editGoalObj) {
      this.onAccomplishmentChange({ type: this.editGoalObj.type.toLowerCase() });
      this.dataForSelectedAccomplisment$.pipe(take(1)).subscribe(res => {
        const selectedAccomplishmentId = this.accomplishments.filter(x => x.type === this.editGoalObj.type.toLowerCase())[0].id;
        this.dynamicFormControls[selectedAccomplishmentId].showSelect = true;
        this.patchEditValue(this.editGoalObj);
    });
    }
  }

  private buildRequestPayloadAdmin(type: AccomplishmentType): any {
    return {
      search: '',
      forAutoComplete: true,
      userId: this.ds.user.userId,
      type: type,
      clientId: this.ds.currentAdminClientId,
      maxModifiedDt: '',
      limit: 10
    };
  }

  private getPayloadForGoalListBasedOnType(type: AccomplishmentType) {
    const clientId = this.ds.currentAdminClientId;
    const userId = this.ds.user.userId;

    return {
      clientId: clientId,
      type: type,
      id: userId,
    }
  }
}
