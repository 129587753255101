import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGoalDetail } from '../goals-track-list';
import { GoalService } from 'src/app/services/goal.service';
import { GoalStatus } from '../goals-track-list/goals-track-list.component';
import { IGoalAbout } from '.';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { take } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { IGoalResponse } from '../../my-goals-layout';

enum PayloadKey {
  CATEGORY = "category",
}

enum GoalCategory {
  RECOMMENDED = "RECOMMENDED",
  ASSIGNED = "ASSIGNED",
}

import { MatDialog } from '@angular/material/dialog';
import { GoalsCheckInComponent } from '../goals-check-in/goals-check-in.component';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { DIALOG_TYPES } from 'src/app/constants';
import { IGoal } from 'src/app/modules/admin/admin-goal-management/components/goals';

@Component({
  selector: 'app-goals-details',
  templateUrl: './goal-details.component.html',
  styleUrls: ['./goal-details.component.scss']
})
export class GoalDetailsComponent implements OnInit {

  @Input() goal: IGoal = null;
  @Input() isTeamGoals: boolean = false;
  @Input() isArchived: boolean = false;

  @Output() goalApproved: EventEmitter<IGoal> = new EventEmitter();
  @Output() goalRejected: EventEmitter<IGoal> = new EventEmitter();
  @Output() goalEdited: EventEmitter<IGoal> = new EventEmitter();
  @Output() goalArchived: EventEmitter<IGoal> = new EventEmitter();
  @Output() goalUnArchived: EventEmitter<IGoal> = new EventEmitter();

  public goalStatusColors: { [key in GoalStatus]: string } = this._goalService.getGoalStatusColors(false);
  public goalStatusBGColors: { [key in GoalStatus]: string } = this._goalService.getGoalStatusBGColors();

  public readonly PROGRESS_STATUS = this._goalService.progressStatus;

  public profileImageUrl: string = null;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(
    public _goalService: GoalService,
    private _us: UtilitiesService,
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private weds: WaitErrorDialogsService
  ) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    const goalId = this.route.snapshot.queryParams['goalId'];
    this.getAllAssignedGoals({ key: 'goalId', value: goalId });

    console.log("NEW DETAILS-->", this.goal)

    // this.goal = {...this.goals[5], goalDescription: this.goalDes};
    // console.log('DETAILS', this.goal);
    // this.goalStatusColors['In Progress']
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  public navigateBack() {
    this.router.navigate(["../myGoals"], { relativeTo: this.route });
  }

  public getColorForGoalStatus(goalStatus: string) {
    return this.goalStatusColors[goalStatus];
  }

  public getBGColorForGoalStatus(goalStatus: string) {
    return this.goalStatusBGColors[goalStatus];
  }

  public getShortName(name: string) {
    return this._us.getShortName(name);
  }

  public onCheckInClick() {
    const dialogRef = this.dialog.open(GoalsCheckInComponent, { width: '600px', panelClass: 'browse-skills' });
    dialogRef.componentInstance.data = this.goal;
    const onEvent = dialogRef.componentInstance.onCheckIn.pipe(take(1)).subscribe((data: any) => {
      dialogRef.close();
      if (data.event === 'checkIn') {
        console.log('Clicked on checkIn')
      }
    })
  }

  public approveGoal() {
    this.goalApproved.emit(this.goal);
  }

  public rejectGoal() {
    this.goalRejected.emit(this.goal);
  }

  public editGoal() {
    this.goalEdited.emit(this.goal);
  }

  public archiveGoal() {
    this.goalArchived.emit(this.goal);
  }

  public unArchive() {
    this.goalUnArchived.emit(this.goal);
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private getClientIdAndUserId(): { clientId: string, userId: string } {
    const clientId = this.ds.currentAdminClientId;
    const userId = this.ds.user.userId || "0e2fbbae-fb5b-44d9-8a8b-4bda9a09cfb1";
    return { clientId, userId };
  }

  private transformGoals(goals: any[]): any[] {
    return goals?.map(goal => ({
      ...goal,
      icon: this._goalService.goalTypeIconsMap[goal?.type?.toLowerCase()],
      selected: false,
    }));
  }

  private getDefaultPayload(filters: any[] = []): any {
    return {
      filters,
      searchKey: "",
      page: 1,
      limit: 10,
    };
  }

  private getAllAssignedGoals(filter: any) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const { clientId } = this.getClientIdAndUserId();
    const payload = this.getDefaultPayload([{ key: PayloadKey.CATEGORY, value: GoalCategory.ASSIGNED }]);
    payload.filters.push(filter);

    const ENDPOINT = this.isTeamGoals ? API_ENDPOINT.GET_GOALS_TEAM_GOALS(clientId): API_ENDPOINT.GET_GOALS_MYGOALS(clientId) ;

    this.ds.careerPrismDataPostApi(ENDPOINT, payload).pipe(take(1)).subscribe((res: IGoalResponse) => {
      console.log(res, 'GET_GOALS_MYGOALS');
      this.weds.closeDialog(dialogRef);
      if (res.ok) {
        console.log(res);
        this.goal = this.transformGoals(res.data?.goals)[0];
        this.profileImageUrl = res.data?.userIdVsProfileImg?.[this.goal.userId];
      }
    });
  }
}
