import { Injectable } from '@angular/core';
import { GoalIcon, GoalStatusType, GoalType } from '../modules/my-goals/my-goals-layout';
import { GoalStatus } from '../modules/my-goals/components/goals-track-list/goals-track-list.component';
import { IGoalMap, IUserInfo } from '../modules/team-goals/team-goals-layout';
import { IGoal } from '../modules/admin/admin-goal-management/components/goals';

const progressStatusSortOrder = {
  GOAL_SET: 1,
  DELAYED: 2,
  COMPLETED: 3
};

@Injectable({
  providedIn: 'root'
})
export class GoalService {

  public progressStatus = {
    IN_REVIEW: 'IN REVIEW',
    GOAL_SET: 'GOAL SET',
    COMPLETED: 'COMPLETED',
    DELAYED: 'DELAYED',
    INCOMPLETE: 'IN COMPLETE',
    REJECTED: 'REJECTED',
  }

  public goalTypes = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'Skill' },
    { id: 2, name: 'Role' },
    { id: 3, name: 'Specialization' },
    { id: 3, name: 'Custom' },
    { id: 3, name: 'Course' },
    { id: 3, name: 'Certification' }
  ];

  public goalStatuses = [
    { id: 0, name: 'Goal Set' },
    { id: 1, name: 'In Review' },
    { id: 2, name: 'In complete' },
    { id: 3, name: 'Completed' },
    { id: 4, name: 'Delayed' },
    { id: 5, name: 'Archived' }
  ];

  public growthPlans = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'Assigned' },
    { id: 2, name: 'Recommended' }
  ];

  public goalCategories = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'Assigned' },
    { id: 2, name: 'Recommended' }
  ];

  public goalTypeIconsMap: { [key: string]: string } = {
    skill:          'electric_bolt',
    custom:         'album',
    course:         'menu_book',
    certification:  'military_tech',
    specialization: 'school',
    role:           'assignment_ind',
  };

  // public goalTypeLabelsMap: { [key: string]: string } = {
  //   skill: 'Develop a skill',
  //   course: 'Complete a course',
  //   certification: 'Complete a certification',
  //   specialization: 'Complete a specialization',
  //   role: 'Develop for a role',
  //   custom: 'Custom Goal',
  // };

  public readonly STATUSES = ['GOAL_SET', 'INREVIEW', 'COMPLETED', 'DELAYED'];
  public readonly IN_REVIEW_STATUSES = ['REJECTED', 'PENDING', 'REVISED'];

  public goalStatusIconMap: any = {
    IN_REVIEW: GoalIcon.PENDING,
    GOAL_SET: GoalIcon.ALBUM,
    COMPLETED: GoalIcon.TICK,
    DELAYED: GoalIcon.SCHEDULE
  }

  public goalStatusColors: { [key in GoalStatus]: string } = {
    [GoalStatus.NotStarted]: '#051C2C',
    [GoalStatus.InProgress]: '#051C2C',
    [GoalStatus.Completed]: '#24A34D',
    [GoalStatus.Archived]: '#051C2C',
    [GoalStatus.RevisedByManager]: '#051C2C',
    [GoalStatus.Revised]: '#4A3700',
    [GoalStatus.Rejected]: '#7A1016',
    [GoalStatus.PendingApproval]: '#051C2C',
    [GoalStatus.GoalSet]: '#051C2C',
    [GoalStatus.Delayed]: '#DA1E28',
    [GoalStatus.AtRisk]: '#7A1016',
    [GoalStatus.OnTrack]: '#EB6300',
    [GoalStatus.InReview]: '#0E0D0F',
    [GoalStatus.Not_Required]: '#0E0D0F',
    [GoalStatus.Recommended]: '#0E0D0F'
  };

  public goalStatusBGColors: { [key in GoalStatus]: string } = {
    [GoalStatus.NotStarted]: '#E6E6EB',
    [GoalStatus.InProgress]: '#E6E6EB',
    [GoalStatus.Completed]: '#A8F0BD',
    [GoalStatus.Archived]: '#E6E6EB',
    [GoalStatus.RevisedByManager]: '#E6E6EB',
    [GoalStatus.Revised]: '#FEF0C2',
    [GoalStatus.Rejected]: '#FED8D9',
    [GoalStatus.PendingApproval]: '#E6E6EB',
    [GoalStatus.GoalSet]: '#E6E6EB',
    [GoalStatus.Delayed]: '#FED8D9',
    [GoalStatus.AtRisk]: '#FED8D9',
    [GoalStatus.OnTrack]: '#FFD8BA',
    [GoalStatus.InReview]: '#F0F0F5',
    [GoalStatus.Not_Required]: '#0E0D0F',
    [GoalStatus.Recommended]: '#0E0D0F'
   };

  constructor() { }

  public getColor(type: GoalStatusType): string {
    switch (type) {
      case GoalStatusType.PRIMARY:
        return 'var(--primary-color-light)';
      case GoalStatusType.INFO:
        return '#051C2C';
      case GoalStatusType.WARNING:
        return '#F59E0B';
      case GoalStatusType.DANGER:
        return 'var(--warning-color-dark)';
      case GoalStatusType.SUCCESS:
        return 'var(--color-green-dark)';
    }
  }
  public getColor2(type: GoalType): string {
    switch (type) {
      case GoalType.SET:
        return 'var(--primary-color-light)';
      case GoalType.SET:
        return '#051C2C';
      case GoalType.REVISED:
        return '#F59E0B';
      case GoalType.DELAYED:
        return 'var(--warning-color-dark)';
      case GoalType.COMPLETED:
        return 'var(--color-green-dark)';
    }
  }

  public getGoalStatusColors(isOverview: boolean = true) {
    const colors = this.goalStatusColors;
    if(!isOverview) {
      colors.COMPLETED = '#034216';
    }
    return colors;
  }

  public getGoalStatusBGColors(isOverview: boolean = true) {
    return this.goalStatusBGColors;
  }

  public createUserGoalsStatusMap(goals: IGoalMap, userGoalsStatusMap: Record<string, any>): Record<string, any> {
    Object.keys(goals).forEach(userId => {
        const userGoals = goals[userId] || [];

        // Initialize counts for each status
        userGoalsStatusMap[userId] = {
            COMPLETED: 0,
            GOAL_SET: 0,
            INCOMPLETE: 0,
            REJECTED: 0,
            DELAYED: 0,
            IN_REVIEW: 0
        };

        userGoals.forEach(goal => {
            // Increment the count based on progressStatus
            if (goal.progressStatus in userGoalsStatusMap[userId]) {
                userGoalsStatusMap[userId][goal.progressStatus]++;
            }

            // Increment the count for IN_REVIEW based on approvalStatus
            if (['REJECTED', 'PENDING', 'REVISED'].includes(goal.approvalStatus)) {
                userGoalsStatusMap[userId]['IN_REVIEW']++;
            }
        });
    });

    return userGoalsStatusMap;
 }

  public getAllSortedGoalsWithProfileImg(goalsData: IGoalMap, userInfo: { [userId: string]: IUserInfo }): IGoal[] {
    const keys: any = Object.keys(goalsData);
    const allGoals = keys.flatMap(userId => {
      const user = userInfo[userId];
      if (!user) return [];

      return goalsData[userId].map(goal => ({
        ...goal,
        profileImg: user.profileImg
      }));
    });

    // Sort all goals first by progressStatus and then by goalDeadline
    allGoals.sort((a: IGoal, b: IGoal) => {
      const statusComparison = progressStatusSortOrder[a.progressStatus] - progressStatusSortOrder[b.progressStatus];
      if (statusComparison === 0) {
        // If progressStatus is the same, sort by goalDeadline
        return new Date(a.goalDeadline).getTime() - new Date(b.goalDeadline).getTime();
      }
      return statusComparison;
    });

    return allGoals;
  }

  public mapUserInfoToObject(userInfo: IUserInfo[]): { [key: string]: IUserInfo } {
    return userInfo?.reduce((acc, user) => {
      if (user.userId) {
        acc[user.userId] = user;
      }
      return acc;
    }, {} as { [key: string]: IUserInfo });
  }

}
