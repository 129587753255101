<button #starButton class="star-container" [ngClass]="getButtonClasses()" [matMenuTriggerFor]="menu"
  [disabled]="!editable && !showRequiredProficiency"
  [matTooltip]="hideRatingValue ? 'Rating: ' + (skillItem[key] || 0) : (editable ? 'Click to edit' : '')"
  matTooltipPosition="above">

  <mat-icon *ngIf="(skillItem && skillItem[key] || 0) === 0" [ngStyle]="{'color': 'gray'}">
    star_border
  </mat-icon>

  <mat-icon *ngIf="(skillItem && skillItem[key] || 0) === maxValue" [ngStyle]="{'color': color || 'gray'}">
    star
  </mat-icon>

  <mat-icon *ngIf="(skillItem && skillItem[key] || 0) > 0 && (skillItem[key] || 0) < maxValue"
    [ngStyle]="{'color': color || 'gray'}">
    star_half
  </mat-icon>

  <div *ngIf="skillItem && !hideRatingValue"
    [ngClass]="skillItem[key] ? 'star-value caption black-text' : 'star-value caption gray'">
    {{ (skillItem[key] || 0) }}
  </div>

  <app-svg-icon
    *ngIf="showRequiredProficiency && skillItem.requiredRating && skillItem.requiredRating > skillItem[key] && !skillItem.changes"
    class="warning-icon" src="icons/warning.svg" fill="var(--color-hot-pink)">
  </app-svg-icon>
</button>
<mat-menu #menu class="star-menu" yPosition="above" xPosition="before">
    <div *ngIf="showRequiredProficiency">
        <div style="margin-bottom: 6px;">Expected Proficiency</div>
        <div>
            <app-star-rating key="requiredRating" [editable]="false" noTooltip="true" [skillItem]="skillItem||{}"
                [client]="client" color="#00A2ED">
            </app-star-rating>
        </div>
        <div class="proficiency-title-con">
          Proficiency
            <app-svg-icon class="prefix" *ngIf="skillItem.requiredRating>skillItem.overallRating&&!skillItem.changes"
                src="icons/warning.svg" fill="var(--color-hot-pink)"></app-svg-icon>
        </div>
    </div>
    <app-star-rating [key]="key" [editable]="editable" [skillItem]="skillItem||{}" [client]="client"
        [noTooltip]="!editable" (onSetValue)="onStarClick($event)">
    </app-star-rating>
</mat-menu>
