<div class="my-goal-cards-main-con">
  <div class="d-flex align-items-center gap-1 mb-4">
    <mat-icon>album</mat-icon>
    <span class="h4">My Goals</span>
  </div>
  <div class="goals-card-con d-flex flex-wrap gap-3">
    <div class="p-3 gap-4 border-radius-8 goals-card" *ngFor="let data of goalsCardData">
      <div class="d-flex align-items-center">
        <mat-icon class="mr-1 goals-icon fs-24" [ngStyle]="{'color': data.color }">{{data.icon}}</mat-icon>
        <span class="h3" [ngStyle]="{'color': data.color }">{{data.value}}</span>
      </div>
      <span class="mt-2 label fw-500" [ngStyle]="{'color': data.color }">{{data.label}}</span>
    </div>
  </div>
</div>
