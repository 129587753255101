import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GoalService } from 'src/app/services/goal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GoalsCheckInComponent } from '../goals-check-in/goals-check-in.component';
import { take } from 'rxjs/operators';
import { IGoal } from 'src/app/modules/admin/admin-goal-management/components/goals';

// All goal statuses + approval statuses
export enum GoalStatus {
  NotStarted = 'NOT STARTED',
  InProgress = 'IN PROGRESS',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED',
  RevisedByManager = 'REVISED BY MANAGER',
  Revised = 'REVISED',
  Rejected = 'REJECTED',
  PendingApproval = 'PENDING',
  GoalSet = 'GOAL_SET',
  Delayed = 'DELAYED',
  AtRisk = 'AT RISK',
  OnTrack = 'ON TRACK',
  InReview = 'INREVIEW',
  Recommended = 'RECOMMENDED',
  Not_Required = 'NOT_REQUIRED'
}

export interface GroupedGoals {
  inReview: IGoal[];
  active: IGoal[];
}

@Component({
  selector: 'app-goals-track-list',
  templateUrl: './goals-track-list.component.html',
  styleUrls: ['./goals-track-list.component.scss']
})
export class GoalsTrackListComponent implements OnChanges {

  @Input() public allAssignedGoals: IGoal[];
  @Input() public isTeamGoal: boolean = false;

  @Output() archiveSelectedEvent = new EventEmitter();
  @Output() acceptRevisedEvent = new EventEmitter();
  @Output() editSelectedEvent = new EventEmitter();
  @Output() checkinEvent = new EventEmitter();

  public goalTypeIcons: { [key: string]: string } = this.goalService.goalTypeIconsMap;
  public goalStatusColors: { [key in GoalStatus]: string } = this.goalService.goalStatusColors;
  public goalStatusBGColors: { [key in GoalStatus]: string } = this.goalService.goalStatusBGColors;

  public groupedGoals: GroupedGoals = { inReview: null, active: null };
  public showAllInReviewGoals: boolean = false;
  public showAllActiveGoals: boolean = false;
  public allInReviewSelected: boolean = false;
  public isSelected: boolean = false;
  public allInReviews: IGoal[] = [];
  public allActive: IGoal[] = [];

  public readonly IN_REVIEW_STATUS: any[] = [
    GoalStatus.Revised,
    GoalStatus.Rejected,
    GoalStatus.PendingApproval
  ];

  public readonly ACTIVE_STATUS: any[] = [
    GoalStatus.GoalSet,
    GoalStatus.Delayed,
    GoalStatus.Completed
  ];
  public readonly PROGRESS_STATUS = this.goalService.progressStatus;
  private readonly CHECK_IN: string = 'checkIn';

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(
    public goalService: GoalService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    ) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.allAssignedGoals && this.allAssignedGoals) {
      this.resetInreviewFlags();
      if(!this.isTeamGoal){
        this.allInReviews = this.getAllInReviews();
        this.allActive = this.getAllActiveGoals();
      } else {
        this.allActive = this.allAssignedGoals;
      }
      this.generateGroupingForInReviewAndActive();
    }
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  public toggleShowAllInReviewGoals() {
    this.showAllInReviewGoals = !this.showAllInReviewGoals;

    if (this.showAllInReviewGoals) {
      this.groupedGoals.inReview = [...this.allInReviews];
    } else {
      this.groupedGoals.inReview = this.groupedGoals.inReview?.slice(0, 3) || [];
    }
  }

  public toggleShowAllActiveGoals() {
    this.showAllActiveGoals = !this.showAllActiveGoals;

    if (this.showAllActiveGoals) {
      this.groupedGoals.active = [...this.allActive];
    } else {
      this.groupedGoals.active = this.groupedGoals.active?.slice(0, 3) || [];
    }
  }

  public getGoalTypeIcon(goalStatus: string) {
    return this.goalTypeIcons[goalStatus];
  }

  public getColorForGoalStatus(goalStatus: string) {
    return this.goalStatusColors[goalStatus];
  }

  public getBGColorForGoalStatus(goalStatus: string) {
    return this.goalStatusBGColors[goalStatus];
  }

  public getCssClassForGoalStatus(status: string): string {
    switch (status) {
      case 'success':
        return 'svg-success';
      case 'warning':
        return 'svg-warning';
      case 'danger':
        return 'svg-danger';
      case 'info':
        return 'svg-info';
      default:
        return '';
    }
  }

  public getSVGPath(type: string) {
    const iconName = this.goalService.goalTypeIconsMap[type.toLowerCase()];
    return `../../../../../assets/svg/${iconName}.svg`;
  }

  public onSelectAllChange(event: { checked: boolean }) {
     const isSelected = event.checked;
    this.allInReviewSelected = isSelected;

    this.allInReviews.forEach(goal => goal.selected = isSelected);
    this.groupedGoals.inReview.forEach(goal => goal.selected = isSelected);
  }

  public onSelectInReviewGoal(goal: IGoal) {
    this.allInReviewSelected = false;
    const selectedGoal = this.allInReviews.find(x => x._id === goal._id);
    if (selectedGoal) {
      selectedGoal.selected = !selectedGoal.selected;
    }

    this.isSelected = this.allInReviews.some(x => x.selected);
  }

  public onAddClick() {
    console.log('clicked');
  }

  public gotoDetailsSection(goal: IGoal) {
    this.router.navigate(['goalDetails'], { relativeTo: this.route, queryParams: { goalId: goal.goalId }});
  }

  public onCheckInClick(goal: IGoal) {
    const dialogRef = this.dialog.open(GoalsCheckInComponent, { width: '600px', panelClass: 'browse-skills' });
    dialogRef.componentInstance.data = goal;
    dialogRef.componentInstance.onCheckIn.pipe(take(1)).subscribe((res: any) => {
      dialogRef.close();
      if (res.event === this.CHECK_IN) {
        this.checkinEvent.emit(res);
      }
    });
  }

  public archiveSelected() {
    const selectedGoals = this.allInReviews.filter(goal => goal.selected);
    this.archiveSelectedEvent.emit(selectedGoals);
  }

  public editSelectedInreviewGoal(goal: IGoal) {
    this.editSelectedEvent.emit(goal);
  }

  public acceptRevisedGoal(goal: IGoal) {
    this.acceptRevisedEvent.emit([goal]);
  }

  public archiveSelectedInreviewGoal(goal: IGoal) {
    this.archiveSelectedEvent.emit([goal]);
  }

  public archiveSelectedActiveGoal(goal: IGoal) {
    this.archiveSelectedEvent.emit([goal]);
  }

  public acceptRevised() {
    const selectedGoals = this.allInReviews.filter(goal => goal.selected);
    this.acceptRevisedEvent.emit(selectedGoals);
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

/**
 * Sort by earliest deadline, status (Revised > Rejected > Pending Approval)
 * @returns IGoal[]
 */
  private getAllInReviews(): IGoal[] {
    return this.allAssignedGoals
      .filter(goal => this.IN_REVIEW_STATUS.includes(goal.approvalStatus))
      .sort((a, b) => {
        const statusOrder: string[] = [GoalStatus.Revised, GoalStatus.Rejected, GoalStatus.PendingApproval];
        const statusComparison = statusOrder.indexOf(a.approvalStatus) - statusOrder.indexOf(b.approvalStatus);

        if (statusComparison !== 0) {
          return statusComparison;
        }

        return new Date(a.goalDeadline).getTime() - new Date(b.goalDeadline).getTime();
      });
  }

  /**
   * Sort by earliest deadline, status (Delayed > Goal Set > Completed)
   * @returns IGoal[]
   */
  private getAllActiveGoals(): IGoal[] {
    return this.allAssignedGoals
      .filter(goal => this.ACTIVE_STATUS.includes(goal.progressStatus))
      .sort((a, b) => {
        const statusOrder: string[] = [GoalStatus.Delayed, GoalStatus.GoalSet, GoalStatus.Completed];
        const statusComparison = statusOrder.indexOf(a.progressStatus) - statusOrder.indexOf(b.progressStatus);

        if (statusComparison !== 0) {
          return statusComparison;
        }

        return new Date(a.goalDeadline).getTime() - new Date(b.goalDeadline).getTime();
      });
  }

  private resetInreviewFlags() {
    this.isSelected = false;
    this.allInReviewSelected = false;
  }

  private generateGroupingForInReviewAndActive() {
    this.groupedGoals = {
      inReview: this.allInReviews?.slice(0, 3) || [],
      active: this.allActive?.slice(0, 3) || []
    };
  }

}
